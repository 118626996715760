@import "../global/___variables"

.slice.our-instructors
    padding-top 30px
    padding-bottom 65px
    .subtitle
        margin-bottom 40px
    .instructors
        .slick-arrow
            top 50%
        .instructor
            .instructor-i
                padding 0 17px
                a
                    cursor pointer
                .img-w
                    display flex
                    align-items: center
                    justify-content center
                    margin-bottom 17px
                    img
                        max-width 100%
                .name-description
                    text-decoration: none
                    .name, .description
                        text-align center
                    .name
                        text-transform uppercase
                        color colorp
                        font-size 14px
                        margin-bottom 4px
                    .description
                        font-size 12px
                        font-weight lighter
                        color texts2
                        margin 0
.remodal
    .name
        text-transform uppercase
        color colorp
        font-size 14px
        margin-bottom 4px
    .description
        font-size 12px
        font-weight lighter
        color texts2
        margin 0
        text-align-last left
    .about
        font-size 15px
