.slice.our-instructors {
  padding-top: 30px;
  padding-bottom: 65px;
}
.slice.our-instructors .subtitle {
  margin-bottom: 40px;
}
.slice.our-instructors .instructors .slick-arrow {
  top: 50%;
}
.slice.our-instructors .instructors .instructor .instructor-i {
  padding: 0 17px;
}
.slice.our-instructors .instructors .instructor .instructor-i a {
  cursor: pointer;
}
.slice.our-instructors .instructors .instructor .instructor-i .img-w {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
}
.slice.our-instructors .instructors .instructor .instructor-i .img-w img {
  max-width: 100%;
}
.slice.our-instructors .instructors .instructor .instructor-i .name-description {
  text-decoration: none;
}
.slice.our-instructors .instructors .instructor .instructor-i .name-description .name,
.slice.our-instructors .instructors .instructor .instructor-i .name-description .description {
  text-align: center;
}
.slice.our-instructors .instructors .instructor .instructor-i .name-description .name {
  text-transform: uppercase;
  color: #003f8f;
  font-size: 14px;
  margin-bottom: 4px;
}
.slice.our-instructors .instructors .instructor .instructor-i .name-description .description {
  font-size: 12px;
  font-weight: lighter;
  color: #666;
  margin: 0;
}
.remodal .name {
  text-transform: uppercase;
  color: #003f8f;
  font-size: 14px;
  margin-bottom: 4px;
}
.remodal .description {
  font-size: 12px;
  font-weight: lighter;
  color: #666;
  margin: 0;
  text-align-last: left;
}
.remodal .about {
  font-size: 15px;
}
